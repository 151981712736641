<template>
	<div class="card">
		<div class="card-body">
			<div class="row">
				<div class="col-md-12">
					<a-tabs @change="onChangeTab" :activeKey="activeTab" :defaultActiveKey="activeTab">
						<a-tab-pane v-for="element in actualSubmenus" :tab="element.title" :key="element.key" force-render>
							<div v-if="element.key == 'dashboard_dashboard'">
								<posDashboard />
							</div>
							<div v-if="element.key == 'dashboard_till'">
								<pos />
							</div>
							<div v-if="element.key == 'dashboard_billing'">
								<billingComponent />
							</div>
						</a-tab-pane>
					</a-tabs>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import posDashboard from '@/views/pos/dashboard'
import pos from '@/views/pos'
import billingComponent from '@/components/billing'
import utilities from '@/services/utilities'
import Swal from 'sweetalert2'

export default {
	name: 'IngresosView',
	components: {
		posDashboard,
		pos,
		billingComponent,
	},
	computed: {
		...mapState(['settings']),
		...mapGetters('pos', ['actualTicket']),
		...mapGetters('user', ['isUserAdmin']),
		actualSubmenus() {
			if (utilities.objectValidate(this.settings, 'menu', false)) {
				let index = this.settings.menu.findIndex((e) => e.url == this.$route.path)
				return this.settings.menu[index].submenus
			} else {
				return []
			}
		},
	},
	data() {
		return {
			activeTab: '',
		}
	},
	mounted() {
		this.onChangeTab(this.actualSubmenus[0].key)
	},
	methods: {
		onChangeTab(key) {
			let theMenu = this.actualSubmenus.find((e) => e.key == key)
			if (!this.isUserAdmin && utilities.objectValidate(theMenu, 'require_auth', false)) {
				this.requireAuthentication(theMenu.id)
			} else {
				this.activeTab = key
			}
		},
		requireAuthentication(module_id) {
			Swal.fire({
				title: 'Ingresos',
				text: 'Ingresa clave de autorización para ingresar al módulo',
				input: 'password',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'Cancelar',
				inputAttributes: {
					autocorrect: 'off',
					autocomplete: 'off',
				},
				inputValidator: (value) => {
					return new Promise((resolve) => {
						if (!value) {
							resolve('Ingresa clave de autorización')
						}
						resolve()
					})
				},
			}).then(async (result) => {
				if (result.isConfirmed) {
					//
					await this.$store
						.dispatch('user/REQUEST_AUTHORIZATION', {
							password: result.value,
							module_id,
						})
						.then((response) => {
							//
							// console.log(response)
							this.activeTab = 'dashboard_dashboard'
						})
						.catch((message) => {
							Swal.fire({
								title: 'Ingresos',
								text: message,
								icon: 'warning',
								confirmButtonText: 'Ok',
							}).then(() => {
								this.requireAuthentication(module_id)
							})
						})
				}
			})
		},
	},
	watch: {
		actualTicket: {
			deep: true,
			handler(newData) {
				if (newData) {
					this.onChangeTab('dashboard_billing')
				}
			},
		},
	},
}
</script>